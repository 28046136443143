<template>
  <v-slide-y-transition>
    <AgingBackstockGraph
      v-if="showReport"
      :reports="backstockAgeReports"
      :loading="reportsLoading"
    />
    <div v-else class="text-center pa-3">
      <p>{{ $t("report.app.agingBackstock.noBackstocksText") }}</p>
      <VxBtn @click="goBackstockSetup">{{
        $t("report.app.agingBackstock.noBackstocksLink")
      }}</VxBtn>
    </div>
  </v-slide-y-transition>
</template>

<script>
import AgingBackstockGraph from "./components/AgingBackstockGraph.vue";
import { BACKSTOCK_AGE_REPORTS_QUERY } from "./graphql";
import { VxBtn } from "@/core-ui";

export default {
  name: "AgingBackstockView",
  components: {
    AgingBackstockGraph,
    VxBtn,
  },

  props: {
    storeId: { type: [String, Number], required: true },
  },

  data() {
    return {
      reportsLoading: false,
    };
  },

  computed: {
    showReport() {
      return !this.backstockAgeReports || this.backstockAgeReports?.length > 0;
    },
  },

  apollo: {
    backstockAgeReports: {
      query: BACKSTOCK_AGE_REPORTS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      watchLoading(isLoading) {
        this.reportsLoading = isLoading;
      },
      skip() {
        return !this.storeId;
      },
      update(data) {
        return data.backstockAgeReports;
      },
    },
  },

  methods: {
    goBackstockSetup() {
      this.$router.push({
        name: "backstock.setup",
      });
    },
  },
};
</script>
