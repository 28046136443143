<template>
  <v-row v-if="loading" justify="center" class="py-9">
    <v-progress-circular indeterminate />
  </v-row>
  <ReportGraph
    v-else-if="this.allCategories.length"
    :loading="loading"
    :build-spec="buildAddedBackstockSpec"
    :daily-reports="graphReports"
    :heights="heightsForCategories"
    :bar-widths="{ default: 15 }"
    :spec-options="specOptions"
  />
  <div v-else class="text-center pa-3">
    <p>{{ $t("report.app.agingBackstock.noBackstocksText") }}</p>
    <VxBtn @click="goBackstockSetup">{{
      $t("report.app.agingBackstock.noBackstocksLink")
    }}</VxBtn>
  </div>
</template>

<script>
import { buildBackstockAgeSpec } from "../specs";
import ReportGraph from "./ReportGraph";
import { VxBtn } from "@/core-ui";

const CATEGORY_LINE_HEIGHT = 25;
const LEGEND_PADDING = 82;

export default {
  name: "AgingBackstockGraph",
  components: {
    ReportGraph,
    VxBtn,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    graphReports() {
      return {
        reports: this.reports,
      };
    },
    specOptions() {
      return {
        legendColumns: this.$vuetify.breakpoint.smAndDown ? 2 : 0,
        allCategories: this.allCategories,
      };
    },
    allCategories() {
      if (!this.reports) {
        return [];
      }
      const reports = this.reports.slice();
      return reports
        .sort(this.compareReports)
        .map((r) => r.categoryName)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
    },
    heightsForCategories() {
      return {
        default:
          this.allCategories.length * CATEGORY_LINE_HEIGHT + LEGEND_PADDING,
      };
    },
    buildAddedBackstockSpec() {
      return (...args) => buildBackstockAgeSpec(...args);
    },
  },
  methods: {
    compareReports(a, b) {
      let aScore = 100;
      if (a.age == "Aged 0-90") {
        aScore = 0;
      } else if (a.age == "Aged 91-180") {
        aScore = 5;
      } else if (a.age == "Aged 181-365") {
        aScore = 10;
      } else if (a.age == "Aged 366+") {
        aScore = 15;
      }

      let bScore = 100;
      if (b.age == "Aged 0-90") {
        bScore = 0;
      } else if (b.age == "Aged 91-180") {
        bScore = 5;
      } else if (b.age == "Aged 181-365") {
        bScore = 10;
      } else if (b.age == "Aged 366+") {
        bScore = 15;
      }

      return aScore < bScore ? -1 : aScore > bScore ? 1 : 0;
    },
    goBackstockSetup() {
      this.$router.push({
        name: "backstock.setup",
      });
    },
  },
};
</script>
